import styled from "styled-components";

// Breakpoints
import { breakpoints } from "../../theme/breakpoints";

const colors = {
  gray: "#f5f5f5",
  darkGray: "#444444",
  violet: "#923FB9"
};

const sizes = {
  m: "18px"
};

export const Root = styled.section`
  width: 30%;
  font-family: "Roboto", sans-serif;
  background: #fff;
  padding: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 2px;

  @media (max-width: ${breakpoints.lg}px) {
    width: 34%;
  }

  @media (max-width: ${breakpoints.md}px) {
    width: 38%;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const Input = styled.input`
  border: 1px solid #dbdbdb;
  height: 40px;
  line-height: 40px;
  width: 100%;
  padding-left: 30px;
  border-radius: 4px;
  font-size: 14px;
  &:placeholder {
    color: #c0c0c0;
  }
`;

export const Label = styled.label`
  position: relative;
`;

export const Icon = styled.img`
  position: absolute;
  top: 2px;
  left: 10px;

  ${({ position }) => position && `position: ${position};`}
  ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft}px;`}
`;

export const Flex = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 50px;
  margin: 10px 0;
  justify-content: space-between;

  

  &:nth-child(2n + 1) {
    background: #f5f5f5;
  }

  ${({ background }) => background && `background: ${colors[background]}`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ borderBottom }) => borderBottom && `border-bottom: ${borderBottom};`}
  ${({ align }) => align && `align-items: ${align};`}
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
`;

export const Tab = styled.div`
  width: 33%;
  text-align: center;
  padding: 8px 5px;
  color: #c0c0c0;
  font-size: 14px;
  cursor: pointer;

  ${({ active }) =>
    active &&
    `border-bottom: 3px solid #923FB9; font-size: 16px; color: #444444;`}
`;

export const Item = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 33%;
  padding-left: 5px;
  color: #c0c0c0;
  font-size: 14px;
  cursor: pointer;
  word-break: break-word;

  ${({ size }) => size && `size: ${sizes[size]};`}
  ${({ color }) => color && `color: ${colors[color]};`}
  ${({ active }) => active && `color: ${colors["violet"]};`}
  ${({ margin }) => margin && `margin: ${margin};`}


`;
