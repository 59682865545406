import styled from "styled-components";

// Breakpoints
import { breakpoints } from "../../theme/breakpoints";

export const Root = styled.div`
  background: #e5e5e5;
  padding: 40px;
  width: 100%;
  min-height: 90vh;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: ${breakpoints.sm}px) {
    flex-wrap: wrap;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 66%;

  @media (max-width: ${breakpoints.lg}px) {
    width: 60%;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
  }
`;
