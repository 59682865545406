import styled from "styled-components";

export const Root = styled.section`
  display: flex;
  flex-direction: column;
  padding: 30px;
  background: #fff;
  margin-bottom: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 2px;
`;

export const Icon = styled.img`
  cursor: pointer;
`;

export const Line = styled.div`
  background: 1px solid #c0c0c0;
  height: 1px;
  margin: 20px 0;
`;

export const Photo = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 7px;
  height: 180px;
  width: 30%;
  position: relative;
`;

export const TextArea = styled.textarea`
  border-radius: 7px;
  height: 180px;
  width: 30%;
  padding: 10px 0 0 10px;
  font-size: 16px;
  resize: none;
  border: none;
  background: #fafafa;
  color: #8a8a8a;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);

  &:placeholder {
    color: #dbdbdb;
  }
`;

export const Img = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
`;
