import styled from "styled-components";

const colors = {
  disabled: "#C0C0C0"
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
`;

export const Pagination = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5px;
  margin-bottom: 10px;
`;

export const PaginationLink = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  cursor: pointer;

  ${({ disabled }) => disabled && `background: ${colors[disabled]};`}
`;

export const GetToPageInput = styled.input`
  width: 45px;
  height: 25px;
  padding-left: 5px;
`;
