import styled from "styled-components";

// Images
import bgImageTopLeft from "./img/bg-left-top.png";
import bgImageRightBottom from "./img/bg-right-bottom.png";

// Breakpoints
import { breakpoints } from "../../theme/breakpoints";

const colors = {
  light: "#A8A5B4",
  success: "#42BA12",
  error: "#BA2612",
  lightViolet: "rgba(68, 30, 218, .3)"
};

export const Root = styled.section`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding: 200px 0;

  background-image: url(${bgImageTopLeft}), url(${bgImageRightBottom}),
    linear-gradient(to left, #261066, #3a1269, #471368, #59176c);
  background-position: left top, right bottom, center center;
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-size: 639px 650px, 560px 663px, 100% 100%;

  color: #2c2a32;
  font-family: "Roboto", sans-serif;

  @media (max-width: ${breakpoints.md}px) {
    background-size: 539px 450px, 460px 463px, 100% 100%;
  }

  @media (max-width: ${breakpoints.sm}px) {
    justify-content: center;
    background-size: 339px 250px, 260px 263px, 100% 100%;
  }
`;

export const Logo = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;

  top: 60px;
  display: flex;
  justify-content: center;
  width: 40%;
  align-items: center;
`;

export const LogoImg = styled.img`
  width: 64px;
  margin-right: 30px;
  cursor: pointer;

  @media (max-width: ${breakpoints.sm}px) {
    width: 54px;
  }
`;

export const LogoDescription = styled.p`
  font-family: "Fira Sans", sans-serif;
  font-weight: 300;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  margin: 0;
  line-height: 16px;

  @media (max-width: ${breakpoints.md}px) {
    font-size: 14px;
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;

  background: #fff;
  width: 32%;
  padding: 40px;
  border-radius: 5px;

  @media (max-width: ${breakpoints.lg}px) {
    width: 44%;
  }

  @media (max-width: ${breakpoints.md}px) {
    width: 70%;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 90%;
    padding: 20px;
  }
`;

export const Label = styled.label`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Input = styled.input`
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #e4e3e8;
  padding-left: 10px;
  height: 50px;
  color: #12072c;
  font-size: 16px;

  ${({ error }) => error && `border: 2px solid #ed2222`}

  @media (max-width: ${breakpoints.sm}px) {
    height: 40px; //
  }
`;

export const Error = styled.div`
  color: #ed2222;
  font-weight: 500;
  margin: 10px 0 10px;

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 14px;
    margin: 5px 0;
  }
`;

export const Button = styled.button`
  width: 100%;
  height: 50px;
  color: #fff;
  cursor: pointer;
  background: #441eda;
  text-transform: uppercase;
  border-radius: 4px;
  border: none;
  margin-top: 10px;
  font-size: 16px;

  ${({ background }) => background && `background: ${colors[background]};`}

  @media (max-width: ${breakpoints.sm}px) {
    height: 40px;//
  }
`;

export const H2 = styled.h2`
  display: flex;
  font-size: 30px;
  margin: 0 0 40px 0;

  font-family: "Fira Sans", sans-serif;

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 26px;
    margin: 0 0 20px 0;
  }
`;

export const IconWrapper = styled.span`
  position: absolute;
  top: 45px;
  right: 20px;

  @media (max-width: ${breakpoints.sm}px) {
    top: 40px;
    right: 10px;
  }
`;

export const List = styled.ul`
  margin: 0;
  padding: 0 0 0 20px;
`;

export const ListItem = styled.li`
  font-size: 14px;
  color: #a8a5b4;
  margin-bottom: 5px;

  ${({ color }) => color && `color: ${colors[color]};`}
`;

export const Text = styled.p`
  margin: 0 0 30px 0;
`;
