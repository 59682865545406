import { call, takeLatest, put, all, fork } from "redux-saga/effects";
import * as types from "../types";

function* signInSaga({ payload }) {
  const { username, password } = payload;

  const encoded = btoa(`${username}:${password}`);
  sessionStorage.setItem("signin", encoded);

  try {
    const data = yield call(() => {
      return fetch(
        `${process.env.REACT_APP_SIGN_UP_URL}/api/v1/identity/admin/profiles`,
        {
          method: "GET",
          headers: {
            Authorization: `Basic ${encoded}`
          }
        }
      ).then(response => response.json());
    });

    if (!data.code) {
      yield put({
        type: types.CHECK_CREDENTIALS_SUCCESS
      });
    } else {
      yield put({
        type: types.CHECK_CREDENTIALS_FAILED,
        payload: data.messages
      });
    }
  } catch (error) {
    yield put({
      type: types.CHECK_CREDENTIALS_FAILED,
      payload: "Try again in a minute."
    });
  }
}

function* getAllUsersSaga({ payload }) {
  const status = payload;

  const credentials = sessionStorage.getItem("signin");

  try {
    const data = yield call(() => {
      return fetch(
        `${process.env.REACT_APP_SIGN_UP_URL}/api/v1/identity/admin/profiles?status=${status}`,
        {
          method: "GET",
          headers: {
            Authorization: `Basic ${credentials}`
          }
        }
      ).then(response => response.json());
    });

    if (data) {
      yield put({
        type: types.GET_ALL_USERS_SUCCESS,
        payload: data
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_ALL_USERS_FAILED,
      payload: "Try again in a minute."
    });
  }
}

function* getCurrentUserSaga({ payload }) {
  const id = payload;
  const credentials = sessionStorage.getItem("signin");

  try {
    const data = yield call(() => {
      return fetch(
        `${process.env.REACT_APP_SIGN_UP_URL}/api/v1/identity/admin/profiles/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Basic ${credentials}`
          }
        }
      ).then(response => response.json());
    });

    if (data) {
      yield put({
        type: types.GET_CURRENT_USER_SUCCESS,
        payload: data
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_CURRENT_USER_FAILED,
      payload: "Try again in a minute."
    });
  }
}

function* getProfileHistorySaga({ payload }) {
  const id = payload;
  const credentials = sessionStorage.getItem("signin");

  try {
    const data = yield call(() => {
      return fetch(
        `${process.env.REACT_APP_SIGN_UP_URL}/api/v1/identity/admin/users/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Basic ${credentials}`
          }
        }
      ).then(response => response.json());
    });

    if (data) {
      yield put({
        type: types.GET_PROFILE_HISTORY_SUCCESS,
        payload: data
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_PROFILE_HISTORY_FAILED,
      payload: "Try again in a minute."
    });
  }
}

function* approveProfileSaga({ payload }) {
  const { id, comment } = payload;
  const credentials = sessionStorage.getItem("signin");

  const body = JSON.stringify({
    status: "approved",
    profile_id: id,
    comment
  });

  try {
    const data = yield call(() => {
      return fetch(
        `${process.env.REACT_APP_SIGN_UP_URL}/api/v1/identity/admin/review`,
        {
          method: "POST",
          headers: {
            Authorization: `Basic ${credentials}`
          },
          body
        }
      ).then(response => response.json());
    });

    if (typeof data.status === "string") {
      yield put({
        type: types.APPROVE_PROFILE_SUCCESS
      });
    } else {
      yield put({
        type: types.APPROVE_PROFILE_FAILED,
        payload: data.messages
      });
    }
  } catch (error) {
    yield put({
      type: types.APPROVE_PROFILE_FAILED,
      payload: "Try again in a minute."
    });
  }
}

function* rejectProfileSaga({ payload }) {
  const { id, comment } = payload;
  const credentials = sessionStorage.getItem("signin");

  const body = JSON.stringify({
    status: "declined",
    profile_id: id,
    comment
  });

  try {
    const data = yield call(() => {
      return fetch(
        `${process.env.REACT_APP_SIGN_UP_URL}/api/v1/identity/admin/review`,
        {
          method: "POST",
          headers: {
            Authorization: `Basic ${credentials}`
          },
          body
        }
      ).then(response => response.json());
    });

    if (typeof data.status === "string") {
      yield put({
        type: types.REJECT_PROFILE_SUCCESS
      });
    } else {
      yield put({
        type: types.REJECT_PROFILE_FAILED,
        payload: data.messages
      });
    }
  } catch (error) {
    yield put({
      type: types.REJECT_PROFILE_FAILED,
      payload: ["Try again in a minute."]
    });
  }
}

/* eslint-disable no-unused-vars */
function* watchSignInSaga() {
  yield takeLatest(types.CHECK_CREDENTIALS_ASYNC, signInSaga);
}

function* watchGetCurrentUserSaga() {
  yield takeLatest(types.GET_CURRENT_USER_ASYNC, getCurrentUserSaga);
}

function* watchGetProfileHistorySaga() {
  yield takeLatest(types.GET_PROFILE_HISTORY_ASYNC, getProfileHistorySaga);
}

function* watchApproveProfileSaga() {
  yield takeLatest(types.APPROVE_PROFILE_ASYNC, approveProfileSaga);
}

function* watchRejectProfileSaga() {
  yield takeLatest(types.REJECT_PROFILE_ASYNC, rejectProfileSaga);
}

function* watchGetAllUsersSaga() {
  yield takeLatest(types.GET_ALL_USERS_ASYNC, getAllUsersSaga);
}

export default function* rootSaga() {
  yield all([
    fork(watchSignInSaga),
    fork(watchGetCurrentUserSaga),
    fork(watchGetProfileHistorySaga),
    fork(watchApproveProfileSaga),
    fork(watchRejectProfileSaga),
    fork(watchGetAllUsersSaga)
  ]);
}
