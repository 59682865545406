import styled from "styled-components";

const sizes = {
  s: "14px",
  m: "16px",
  lg: "18px",
  xl: "20px"
};

const colors = {
  darkGray: "#2C2A32",
  lightGray: "#C0C0C0"
};

export const Text = styled.span`
${({ size }) => size && `font-size: ${sizes[size]};`}
${({ color }) => color && `color: ${colors[color]};`}

${({ bold }) => bold && `font-weight: 600;`}

`;
