import React from "react";

// Styles
import { Root, Logo, LogoImg, LogoDescription } from "./styles";

// Img
import siteLogoImg from "../commonFiles/site-logo.svg";

const Header = () => {
  return (
    <Root>
      <Logo>
        <LogoImg src={siteLogoImg} />
        <LogoDescription>
          Ethereum <br /> Express <br /> Coin
        </LogoDescription>
      </Logo>
    </Root>
  );
};

export default Header;
