import * as types from "./types";

export const checkCredentialsAction = data => ({
  type: types.CHECK_CREDENTIALS_ASYNC,
  payload: data
});

export const setLogInAction = isLoggedIn => ({
  type: types.SET_LOG_IN,
  payload: isLoggedIn
});

export const getCurrentUserAction = id => ({
  type: types.GET_CURRENT_USER_ASYNC,
  payload: id
});

export const getProfileHistoryAction = id => ({
  type: types.GET_PROFILE_HISTORY_ASYNC,
  payload: id
});

export const approveProfileAction = id => ({
  type: types.APPROVE_PROFILE_ASYNC,
  payload: id
});

export const rejectProfileAction = id => ({
  type: types.REJECT_PROFILE_ASYNC,
  payload: id
});

export const getAllUsersAction = status => ({
  type: types.GET_ALL_USERS_ASYNC,
  payload: status
});
