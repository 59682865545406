// Absolute imports
import React, { useState, useEffect } from "react";

// Styles
import { Pagination, PaginationLink, GetToPageInput, Wrapper } from "./styles";

const PaginationComponent = ({
  items,
  itemsPerPage,
  currentPage,
  setPage,
  centerPage,
  activeTab,
  setCenterPage,
  setCurrentItems,
  sortedCount
}) => {
  const totalAmount = items.length;
  const [inputValue, setInputValue] = useState("1");

  const disabledPages = new Array(5).fill(null).map((item, index) => {
    return Math.ceil(items.length / itemsPerPage) + (index + 1);
  });

  const lastAvailablePage = disabledPages[0] - 1;

  useEffect(() => {
    resetToInitialState();
  }, [activeTab, totalAmount]);

  useEffect(() => {
    const newItems =
      currentPage === 1
        ? items.slice(0, itemsPerPage)
        : items.slice(
            currentPage * itemsPerPage - itemsPerPage,
            currentPage * itemsPerPage
          );

    setCurrentItems(newItems);
  }, [currentPage, sortedCount]);

  const pages = [];

  for (let i = centerPage - 2; i <= centerPage + 2; i++) {
    pages.push(i);
  }

  const paginate = (page, direction) => {
    if (!direction) {
      setPagesAndInput(page);

      if (page > 2) {
        setCenterPage(page);
      }
    }

    if (direction === "prev") {
      if (page > 1) {
        setPagesAndInput(page - 1);

        if (page > 3) {
          setCenterPage(page - 1);
        }
      }
    }

    if (direction === "next") {
      if (page < Math.ceil(totalAmount / itemsPerPage)) {
        setPagesAndInput(page + 1);

        if (page >= 3) {
          setCenterPage(page + 1);
        }
      }
    }
  };

  const setPagesAndInput = page => {
    setPage(page);
    setInputValue(String(page));
  };

  const resetToInitialState = () => {
    setInputValue("1");
    setPage(1);
    setCenterPage(3);
  };

  const handleKeyPress = event => {
    if (event.key === "Enter") {
      validateInput(event.target.value);
    }
  };

  const handleInputChange = event => {
    setInputValue(event.target.value);
  };

  const validateInput = value => {
    if (isNaN(+value) || value.startsWith("0") || !value) {
      resetToInitialState();
      return;
    }

    if (value > lastAvailablePage) {
      if (!lastAvailablePage) {
        resetToInitialState();
        return;
      }
      setPagesAndInput(lastAvailablePage);

      lastAvailablePage > 2
        ? setCenterPage(lastAvailablePage)
        : setCenterPage(3);
      return;
    }

    value <= 2 ? setCenterPage(3) : setCenterPage(+value);
    setPagesAndInput(+value);
  };

  return (
    <Wrapper>
      <Pagination>
        <PaginationLink onClick={() => paginate(currentPage, "prev")}>
          «
        </PaginationLink>

        {/* show only first 5 pages, hide other */}
        {pages.map((page, index) => {
          return (
            <PaginationLink
              disabled={disabledPages.includes(page)}
              onClick={
                disabledPages.includes(page) ? () => {} : () => paginate(page)
              }
              style={
                currentPage === page
                  ? { background: "#923FB9", color: "#fff" }
                  : disabledPages.includes(page)
                  ? { color: "#C0C0C0" }
                  : { color: "#923FB9" }
              }
              disabled={disabledPages.includes(page)}
              key={index}
            >
              {page}
            </PaginationLink>
          );
        })}

        <PaginationLink onClick={() => paginate(currentPage, "next")}>
          {" "}
          »{" "}
        </PaginationLink>
      </Pagination>{" "}
      <GetToPageInput
        type="text"
        value={inputValue}
        onKeyPress={handleKeyPress}
        onChange={handleInputChange}
      />
    </Wrapper>
  );
};

export default PaginationComponent;
