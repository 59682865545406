import React from "react";

// Styles
import { Root, Wrapper } from "./styles";

// Components
import Header from "../Header";
import UsersList from "../UsersList";
import SelectedUser from "../SelectedUser";
import History from "../History";

const Main = () => {
  return (
    <>
      <Header />
      <Root>
        <UsersList />
        <Wrapper>
          <SelectedUser />
          <History />
        </Wrapper>
      </Root>
    </>
  );
};

export default Main;
