export const CHECK_CREDENTIALS_ASYNC = "CHECK_CREDENTIALS_ASYNC";
export const CHECK_CREDENTIALS_SUCCESS = "CHECK_CREDENTIALS_SUCCESS";
export const CHECK_CREDENTIALS_FAILED = "CHECK_CREDENTIALS_FAILED";
export const SET_LOG_IN = "SET_LOG_IN";
export const GET_CURRENT_USER_ASYNC = "SET_CURRENT_USER_ASYNC";
export const GET_CURRENT_USER_SUCCESS = "GET_CURRENT_USER_SUCCESS";
export const GET_CURRENT_USER_FAILED = "GET_CURRENT_USER_FAILED";
export const GET_PROFILE_HISTORY_ASYNC = "GET_PROFILE_HISTORY_ASYNC";
export const GET_PROFILE_HISTORY_SUCCESS = "GET_PROFILE_HISTORY_SUCCESS";
export const GET_PROFILE_HISTORY_FAILED = "GET_PROFILE_HISTORY_FAILED";
export const APPROVE_PROFILE_ASYNC = "APPROVE_PROFILE_ASYNC";
export const APPROVE_PROFILE_SUCCESS = "APPROVE_PROFILE_SUCCESS";
export const APPROVE_PROFILE_FAILED = "APPROVE_PROFILE_FAILED";
export const REJECT_PROFILE_ASYNC = "REJECT_PROFILE_ASYNC";
export const REJECT_PROFILE_SUCCESS = "REJECT_PROFILE_SUCCESS";
export const REJECT_PROFILE_FAILED = "REJECT_PROFILE_FAILED";
export const GET_ALL_USERS_ASYNC = "GET_ALL_USERS_ASYNC";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAILED = "GET_ALL_USERS_FAILED";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
