import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";

// Components
import Pagination from "../Pagination";

// Styles
import { Root, Input, Label, Icon, Flex, Tab, Item } from "./styles";

// Icons
import search from "./search.svg";
import arrows from "./arrows.svg";

// Action creators
import {
  getCurrentUserAction,
  getAllUsersAction
} from "../../redux/actionCreators";

// Helpers
import { formatDate, sortByDate, sortByName } from "../../helpers";

// Constants
import { sortingOrders } from "../../constants";

const UsersList = ({
  getAllUsers,
  allUsers,
  getCurrentUser,
  profileRejectedCount,
  profileApprovedCount
}) => {
  const [
    { usersPerPage, activeTab, searchValue, centerPage },
    setState
  ] = useState({
    usersPerPage: 10,
    activeTab: "review",
    searchValue: "",
    centerPage: 3
  });

  const [page, setPage] = useState(1);
  const [users, setUsers] = useState([]);
  const [currentUsers, setCurrentUsers] = useState([]);
  const [dateSortOrder, setDateSortOrder] = useState(sortingOrders.asc);
  const [nameSortOrder, setNameSortOrder] = useState(sortingOrders.desc);
  const [sortedCount, setSortedCount] = useState(0);

  const filteredUsers = useMemo(
    () =>
      searchValue &&
      allUsers.filter(
        user =>
          user.first_name.toLowerCase().indexOf(searchValue.toLowerCase()) !==
            -1 ||
          user.last_name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
      ),
    [searchValue, allUsers]
  );

  const currentFilteredUsers = filteredUsers.slice(
    (page - 1) * 10,
    usersPerPage + (page - 1) * 10
  );

  useEffect(() => {
    getAllUsers("review");
  }, []);

  useEffect(() => {
    const sortedUsers = sortByDate(allUsers, sortingOrders.desc);
    setUsers(sortedUsers);
  }, [allUsers]);

  useEffect(() => {
    users.length &&
      users[0] &&
      getCurrentUser(users[0].id) &&
      setSortedCount(sortedCount + 1);
  }, [users, dateSortOrder, nameSortOrder]);

  useEffect(() => {
    filteredUsers.length && getCurrentUser(filteredUsers[0].id);
  }, [filteredUsers]);

  useEffect(() => {
    handleTabClick("review");
  }, [profileRejectedCount, profileApprovedCount]);

  const handleTabClick = status => {
    setState(values => ({
      ...values,
      activeTab: status,
      searchValue: ""
    }));

    getAllUsers(status);
  };

  const handleNameSort = event => {
    const { id } = event.target.dataset;

    const sorted = sortByName(users, id, nameSortOrder);

    setUsers(sorted);
    setNameSortOrder(
      nameSortOrder === sortingOrders.asc
        ? sortingOrders.desc
        : sortingOrders.asc
    );
  };

  const handleDateSort = () => {
    const sorted = sortByDate(users, dateSortOrder);

    setUsers(sorted);
    setDateSortOrder(
      dateSortOrder === sortingOrders.asc
        ? sortingOrders.desc
        : sortingOrders.asc
    );
  };

  const setCenterPage = centerPage => {
    setState(values => ({
      ...values,
      centerPage
    }));
  };

  const handleSearch = event => {
    const { name, value } = event.target;

    setState(values => ({
      ...values,
      [name]: value
    }));
  };

  return (
    <>
      <Root>
        <Label>
          <Input
            type="text"
            placeholder="Search by Name, Surname"
            data-test="search"
            value={searchValue}
            onChange={handleSearch}
            name="searchValue"
          />
          <Icon src={search} alt="search-icon" />
        </Label>
        <Flex borderBottom="1px solid #c0c0c0" align="flex-end">
          <Tab
            active={activeTab === "review"}
            onClick={() => handleTabClick("review")}
          >
            Review
          </Tab>
          <Tab
            active={activeTab === "approved"}
            onClick={() => handleTabClick("approved")}
          >
            Approved
          </Tab>
          <Tab
            active={activeTab === "declined"}
            onClick={() => handleTabClick("declined")}
          >
            Declined
          </Tab>
        </Flex>
        <Flex margin="0px">
          <Item data-id="first_name" onClick={handleNameSort}>
            Name
            <Icon
              src={arrows}
              alt="arrows-icon"
              position="static"
              marginLeft={5}
            />
          </Item>
          <Item data-id="last_name" onClick={handleNameSort}>
            Surname
            <Icon
              src={arrows}
              alt="arrows-icon"
              position="static"
              marginLeft={5}
            />
          </Item>
          <Item onClick={handleDateSort}>
            Time
            <Icon
              src={arrows}
              alt="arrows-icon"
              position="static"
              marginLeft={5}
            />
          </Item>
        </Flex>
        {Boolean(currentUsers.length) ? (
          currentFilteredUsers.length ? (
            currentFilteredUsers.map(user => (
              <Flex
                margin="0px"
                key={user.id}
                cursor="pointer"
                onClick={() => {
                  getCurrentUser(user.id);
                }}
              >
                <Item size="m" color="darkGray">
                  {user.first_name}
                </Item>
                <Item size="m" color="darkGray">
                  {user.last_name}
                </Item>
                <Item>{formatDate(user.created_at)}</Item>
              </Flex>
            ))
          ) : searchValue && !filteredUsers.length ? (
            <Item size="s" color="darkGray" margin="10px 0">
              User not found.
            </Item>
          ) : (
            currentUsers.map(user => (
              <Flex
                margin="0px"
                key={user.id}
                cursor="pointer"
                onClick={() => {
                  getCurrentUser(user.id);
                }}
              >
                <Item size="m" color="darkGray">
                  {user.first_name}
                </Item>
                <Item size="m" color="darkGray">
                  {user.last_name}
                </Item>
                <Item>{formatDate(user.created_at)}</Item>
              </Flex>
            ))
          )
        ) : (
          <Item size="s" color="darkGray" margin="10px 0">
            No users.
          </Item>
        )}
        <Pagination
          items={
            filteredUsers.length ? filteredUsers : searchValue ? [] : users
          }
          itemsPerPage={10}
          currentPage={page}
          setPage={setPage}
          centerPage={centerPage}
          activeTab={activeTab}
          setCenterPage={setCenterPage}
          setCurrentItems={setCurrentUsers}
          sortedCount={sortedCount}
        />
      </Root>
    </>
  );
};

const mapStateToProps = state => ({
  allUsers: state.users.allUsers,
  profileRejectedCount: state.users.profileRejectedCount,
  profileApprovedCount: state.users.profileApprovedCount
});

const mapDispatchToProps = dispatch => ({
  getCurrentUser: userId => dispatch(getCurrentUserAction(userId)),
  getAllUsers: status => dispatch(getAllUsersAction(status))
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
