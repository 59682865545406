import styled from "styled-components";

// Breakpoints
import { breakpoints } from "../../theme/breakpoints";

const themes = {
  main: "background: #441EDA; color: #fff;",
  light: "background: #fff; color: #441EDA; border: 1px solid #441EDA;"
};

export const Root = styled.section`
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  padding: 30px;
  margin-bottom: 30px;
`;

export const Line = styled.div`
  background: 1px solid #c0c0c0;
  height: 1px;
  margin: 20px 0;
`;

export const Photo = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 7px;
  height: 180px;
  width: 30%;
  position: relative;
`;

export const TextArea = styled.textarea`
  border-radius: 7px;
  height: 180px;
  width: 30%;
  padding: 10px 0 0 10px;
  font-size: 16px;
  resize: none;
  border: none;
  background: #fafafa;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);
  color: #8a8a8a;
   
  &:placeholder {
    color: #dbdbdb;
  }
   ${({ error }) => error && `border: 2px solid #ff3030;`} 
`;

export const Button = styled.button`
display: flex;
justify-content: center;
align-items: center;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  width: 16%;
  height: 40px;
  margin-left: 20px;
  cursor: pointer;
  ${({ theme }) => theme && `${themes[theme]};`}
  ${({ disable }) =>
    disable &&
    `background: lightgray; color: #fff; border: 1px solid lightgray;`}


  @media (max-width: ${breakpoints.lg}px) {
    width: 28%;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 30%;
  }
`;

export const Img = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
`;

export const Error = styled.p`
  font-weight: bold;
  font-size: 14px;
  color: #ff3030;
`;
