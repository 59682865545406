// Absolute imports
import styled, { keyframes } from "styled-components";

// Breakpoints
import { breakpoints } from "../../theme/breakpoints";

const colors = {
  light: "#a987e5",
  dark: "#441eda"
};

const sizes = {
  sm: {
    width: "9px",
    height: "9px",
    top: "15px"
  },
  lg: {
    width: "13px",
    height: "13px",
    top: "15px"
  }
};

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: center;

  align-items: center;
`;

export const LoadingWrapper = styled.div`
  display: inline-block;
  position: relative;

  width: 80px;
  height: 40px;

  @media (max-width: ${breakpoints.sm}px) {
    top: -1px;
  }
`;

const ldsEllipsis1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

const ldsEllipsis2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
`;

const ldsEllipsis3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

export const LoadingChild = styled.div`
  position: absolute;

  border-radius: 50%;
  background: #a987e5;

  ${({ color }) => color && `background: ${colors[color]};`}

  ${({ size }) =>
    size &&
    `width: ${sizes[size].width}; height: ${sizes[size].height};top: ${sizes[size].top};`}


  animation-timing-function: cubic-bezier(0, 1, 1, 0);

  @media (max-width: ${breakpoints.sm}px) {
    top: 12px;
  }

  &:nth-child(1) {
    left: 8px;
    animation: ${ldsEllipsis1} 0.6s infinite;
  }

  &:nth-child(2) {
    left: 8px;
    animation: ${ldsEllipsis2} 0.6s infinite;
  }

  &:nth-child(3) {
    left: 30px;
    animation: ${ldsEllipsis2} 0.6s infinite;
  }

  :nth-child(4) {
    left: 52px;
    animation: ${ldsEllipsis3} 0.6s infinite;
  }
`;

const fishMove = keyframes`
  0% {
    transform: translate(0, 0);s
  }

  100% {
    transform: translate(74px, 0);
  }
}
`;

export const Svg = styled.svg`
  animation: ${fishMove} 2s infinite;
  position: relative;
  top: -2px;

  @media (max-width: ${breakpoints.sm}px) {
    top: -7px;
  }
`;
