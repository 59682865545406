import { combineReducers } from "redux";
import * as types from "./types";

const signInState = {
  isLoggedIn: false,
  logInErrors: []
};

const usersState = {
  getUsersError: null,
  currentUser: null,
  profileHistory: [],
  getProfileHistoryError: null,
  profileRejectedCount: 0,
  profileApprovedCount: 0,
  rejectProfileErrors: [],
  approveProfileErrors: [],
  allUsers: [],
  getAllUsersErrors: []
};

const signInReducer = (state = signInState, { type, payload }) => {
  switch (type) {
    case types.CHECK_CREDENTIALS_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        logInErrors: []
      };
    case types.CHECK_CREDENTIALS_FAILED:
      return {
        ...state,
        isLoggedIn: false,
        logInErrors: payload
      };
    case types.SET_LOG_IN:
      return {
        ...state,
        isLoggedIn: true
      };
    default:
      return state;
  }
};

const usersReducer = (state = usersState, { type, payload }) => {
  switch (type) {
    case types.GET_CURRENT_USER_FAILED:
      return {
        ...state,
        getUsersError: payload
      };
    case types.GET_CURRENT_USER_SUCCESS:
    case types.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: payload
      };
    case types.GET_PROFILE_HISTORY_SUCCESS:
      return {
        ...state,
        profileHistory: payload,
        getProfileHistoryError: null
      };
    case types.GET_PROFILE_HISTORY_FAILED:
      return {
        ...state,
        getProfileHistoryError: payload
      };
    case types.APPROVE_PROFILE_SUCCESS:
      return {
        ...state,
        profileApprovedCount: state.profileApprovedCount + 1
      };
    case types.APPROVE_PROFILE_FAILED:
      return {
        ...state,
        approveProfileErrors: payload,
        profileApprovedCount: state.profileApprovedCount + 1
      };
    case types.REJECT_PROFILE_SUCCESS:
      return {
        ...state,
        profileRejectedCount: state.profileRejectedCount + 1
      };
    case types.REJECT_PROFILE_FAILED:
      return {
        ...state,
        rejectProfileErrors: payload,
        profileRejectedCount: state.profileRejectedCount + 1
      };
    case types.GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        allUsers: payload
      };
    case types.GET_ALL_USERS_FAILED:
      return {
        ...state,
        getAllUsersErrors: payload
      };

    default:
      return state;
  }
};

const rootReducer = combineReducers({
  signIn: signInReducer,
  users: usersReducer
});

export default rootReducer;
