// Absolute imports
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import uuid from "uuid/v4";

// Styles
import {
  Root,
  Logo,
  LogoImg,
  LogoDescription,
  FormWrapper,
  Label,
  Input,
  Error,
  Button,
  H2,
  IconWrapper
} from "./styles";

// Components
import Eye from "../Eye";
import Loading from "../Loading";

// Images
import siteLogoImg from "./site-logo.svg";

// Action creators
import { checkCredentialsAction } from "../../redux/actionCreators";

// Helpers
import { convertError } from "../../helpers/convertError";

const SignIn = ({
  // isVerified,
  // checkCredentials,
  // logInError,
  // attempt,
  // history,
  isLoggedIn,
  checkCredentials,
  logInErrors
  // isLoggedIn
}) => {
  const [
    {
      userName,
      userNameError,
      password,
      passwordError,
      isPasswordHidden,
      errors,
      isLoading
    },
    setState
  ] = useState({
    userName: "",
    userNameError: null,
    password: "",
    passwordError: "",
    isPasswordHidden: true,
    errors: [],
    isLoading: false
  });

  useEffect(() => {
    if (logInErrors.length) {
      setState(values => ({
        ...values,
        errors: logInErrors,
        isLoading: false,
        userName: "",
        password: ""
      }));
    }
  }, [logInErrors]);

  const validateField = value => {
    if (!value) {
      return "This field is required";
    }
    return null;
  };

  const handleChange = event => {
    const {
      target: { value, name }
    } = event;

    setState(values => ({
      ...values,
      [name]: value
    }));
  };

  const handleSubmit = event => {
    event.preventDefault();

    setState(values => ({
      ...values,
      isLoading: true
    }));

    const userNameError = validateField(userName);
    const passwordError = validateField(password);

    if (userNameError || passwordError) {
      setState(values => ({
        ...values,
        userNameError,
        passwordError,
        userName: "",
        password: "",
        isLoading: false
      }));
      return;
    }

    checkCredentials({ username: userName, password });
  };

  const togglePasswordVisibility = () => {
    setState(values => ({
      ...values,
      isPasswordHidden: !isPasswordHidden
    }));
  };

  return (
    <>
      <Root>
        <Logo>
          <LogoImg src={siteLogoImg} alt="Logo" />
          <LogoDescription>
            Ethereum
            <br /> Express
            <br /> Coin
          </LogoDescription>
        </Logo>

        <FormWrapper>
          <form onSubmit={handleSubmit}>
            <>
              <H2>Sign in</H2>
              <Label>
                Email <br />
                <Input
                  value={userName}
                  onChange={handleChange}
                  type="text"
                  name="userName"
                  placeholder="Enter username"
                  error={userNameError}
                  data-test="sign-in-username"
                />
              </Label>

              {userNameError && (
                <Error data-test="sign-in-email-error">{userNameError}</Error>
              )}

              <Label>
                Password
                <Input
                  value={password}
                  onChange={handleChange}
                  type={isPasswordHidden ? "password" : "text"}
                  name="password"
                  placeholder="Enter password"
                  error={passwordError}
                  data-test="sign-in-password"
                />
                <IconWrapper
                  data-test="sign-in-eye"
                  onClick={togglePasswordVisibility}
                >
                  <Eye color={isPasswordHidden ? "light" : "main"} />
                </IconWrapper>
              </Label>

              {passwordError && (
                <Error data-test="sign-in-password-error">
                  {passwordError}
                </Error>
              )}

              {Boolean(errors.length) &&
                errors.map(error => (
                  <Error data-test="sign-in-general-error" key={uuid()}>
                    {convertError(error)}
                  </Error>
                ))}

              <Button
                data-test="sign-in-button"
                background={isLoading ? "lightViolet" : "main"}
              >
                {" "}
                {isLoading ? <Loading /> : <span>Sign in</span>}{" "}
              </Button>
            </>
          </form>
        </FormWrapper>
      </Root>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  // isVerified: state.registration.isVerified,
  // attempt: state.registration.attemptForLogIn,
  // logInError: state.registration.logInError,
  isLoggedIn: state.signIn.isLoggedIn,
  logInErrors: state.signIn.logInErrors
});

const mapDispatchToProps = dispatch => ({
  // checkCredentials: credentials =>
  //   dispatch(checkCredentialsAction(credentials)),
  checkCredentials: data => dispatch(checkCredentialsAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
