// Absolute imports
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import uuid from "uuid/v4";

// Styles
import { Root, Button, Line, Photo, TextArea, Img, Error } from "./styles";
import { Flex } from "../UI/Flex";
import { Text } from "../UI/Typography";

// Helpers
import { formatDate } from "../../helpers/formatDate";

// Action creators
import {
  approveProfileAction,
  rejectProfileAction
} from "../../redux/actionCreators";

const SelectedUser = ({
  currentUser,
  approveProfile,
  rejectProfile,
  approveProfileErrors,
  rejectProfileErrors,
  profileApprovedCount,
  profileRejectedCount
}) => {
  const [comment, setComment] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isApproved] = useState(profileApprovedCount);
  const [isRejected] = useState(profileRejectedCount);

  useEffect(() => {
    setError("");
  }, [currentUser]);

  useEffect(() => {
    (profileApprovedCount !== isApproved ||
      profileRejectedCount !== isRejected) &&
      setIsLoading(false);
  }, [profileApprovedCount, profileRejectedCount]);

  const approve = () => {
    setIsLoading(true);
    approveProfile({ id: currentUser.id, comment });
    setComment("");
  };

  const reject = () => {
    if (!comment) {
      setError("Comment is required.");
      return;
    }
    setIsLoading(true);
    rejectProfile({ id: currentUser.id, comment });
    setComment("");
  };

  return currentUser ? (
    <Root>
      <Flex justify="space-between">
        <Flex direction="column" justify="space-between" width="24%">
          <Text size="s" color="lightGray">
            Full Name
          </Text>
          <Text size="xl" bold color="darkGray">
            {currentUser.first_name} {currentUser.last_name}
          </Text>
        </Flex>
        <Flex direction="column" justify="space-between" width="24%">
          <Text size="s" color="lightGray">
            Passport/ ID
          </Text>
          <Text size="m" color="darkGray">
            {currentUser.doc_number}
          </Text>
        </Flex>
        <Flex direction="column" justify="space-between" width="24%">
          <Text size="s" color="lightGray">
            Country
          </Text>
          <Text size="m" color="darkGray">
            {currentUser.country}
          </Text>
        </Flex>
        <Flex direction="column" justify="space-between" width="24%">
          <Text size="s" color="lightGray">
            Time of Apply
          </Text>
          <Text size="m" color="darkGray">
            {formatDate(currentUser.created_at)}
          </Text>
        </Flex>
      </Flex>

      <Line />

      <Flex
        justify={
          currentUser.status === "review" ? "space-between" : "space-around"
        }
      >
        <Photo>
          <a target="_blank" href={currentUser.doc_photo_url}>
            <Img src={currentUser.doc_photo_url} alt="Document photo" />
          </a>
        </Photo>
        <Photo>
          <a target="_blank" href={currentUser.person_photo_url}>
            <Img src={currentUser.person_photo_url} alt="User photo" />
          </a>
        </Photo>

        {currentUser.status === "review" && (
          <TextArea
            value={comment}
            onChange={event => setComment(event.target.value)}
            error={Boolean(error)}
          ></TextArea>
        )}
      </Flex>

      <Line />

      {currentUser.status === "review" && (
        <Flex justify="flex-end">
          <Button
            theme="light"
            onClick={reject}
            disabled={isLoading}
            disable={isLoading}
          >
            Reject
          </Button>
          <Button
            onClick={approve}
            theme="main"
            disabled={isLoading}
            disable={isLoading}
          >
            Approve
          </Button>
        </Flex>
      )}
      {Boolean(approveProfileErrors.length) &&
        approveProfileErrors.map(error => <Error key={uuid()}>{error}</Error>)}

      {Boolean(rejectProfileErrors.length) &&
        rejectProfileErrors.map(error => <Error key={uuid()}>{error}</Error>)}

      {Boolean(error) && <Error>{error}</Error>}
    </Root>
  ) : (
    <p>No users for this category.</p>
  );
};

const mapStateToProps = state => ({
  currentUser: state.users.currentUser,
  approveProfileErrors: state.users.approveProfileErrors,
  rejectProfileErrors: state.users.rejectProfileErrors,
  profileApprovedCount: state.users.profileApprovedCount,
  profileRejectedCount: state.users.profileRejectedCount
});

const mapDispatchToProps = dispatch => ({
  approveProfile: data => dispatch(approveProfileAction(data)),
  rejectProfile: data => dispatch(rejectProfileAction(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(SelectedUser);
