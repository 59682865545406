// Absolute imports
import React, { useEffect } from "react";
import { connect } from "react-redux";

// Components
import SignIn from "../SignIn";
import Main from "../Main";

// Theme
import GlobalStyle from "../../theme/global-style";

// Action creators
import { setLogInAction } from "../../redux/actionCreators";

const pkj = require("../../../package.json");

const App = ({ isLoggedIn, setLogIn }) => {
  useEffect(() => {
    console.log(
      `%c current version is: ${pkj.version}`,
      "color: orange; font-size: 13px;"
    );
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("signin")) {
      setLogIn(true);
    }
  }, []);

  return (
    <>
      <GlobalStyle />

      {isLoggedIn ? <Main /> : <SignIn />}
    </>
  );
};

const mapStateToProps = state => ({
  isLoggedIn: state.signIn.isLoggedIn
});

const mapDispatchToProps = dispatch => ({
  setLogIn: isLoggedIn => dispatch(setLogInAction(isLoggedIn))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
