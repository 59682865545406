import React, { useState } from "react";

// Styles
import { Root, Icon, Line, Photo, TextArea, Img } from "./styles";
import { Flex } from "../UI/Flex";
import { Text } from "../UI/Typography";

// Icons
import arrowUp from "./arrow-up.svg";
import arrowDown from "./arrow-down.svg";

// Helpers
import { formatDate } from "../../helpers/formatDate";

const HistoryItem = ({ data }) => {
  const [isOpened, setIsOpened] = useState(false);

  const {
    first_name,
    last_name,
    doc_number,
    country,
    doc_photo_url,
    person_photo_url,
    created_at,
    review
  } = data;

  const toggleMenu = () => {
    setIsOpened(!isOpened);
  };

  return (
    <Root>
      {/* <Flex direction="column"> */}
      <Flex justify="space-between">
        <Flex direction="column" justify="space-between" width="24%">
          <Text size="s" color="lightGray">
            Full Name
          </Text>
          <Text size="xl" bold color="darkGray">
            {first_name} {last_name}
          </Text>
        </Flex>
        <Flex direction="column" justify="space-between" width="24%">
          <Text size="s" color="lightGray">
            Passport/ ID
          </Text>
          <Text size="m" color="darkGray">
            {doc_number}
          </Text>
        </Flex>
        <Flex direction="column" justify="space-between" width="24%">
          <Text size="s" color="lightGray">
            Country
          </Text>
          <Text size="m" color="darkGray">
            {country}
          </Text>
        </Flex>
        <Flex direction="column" justify="space-between" width="24%">
          <Text size="s" color="lightGray">
            Time of Apply
          </Text>
          <Text size="m" color="darkGray">
            {formatDate(created_at)}
          </Text>
        </Flex>
        <Flex direction="column" justify="flex-end">
          <Icon onClick={toggleMenu} src={isOpened ? arrowUp : arrowDown} />
        </Flex>
      </Flex>
      {isOpened && (
        <>
          <Line />
          <Flex justify="space-between">
            <Photo>
              <Img src={doc_photo_url} alt="Passport photo" />
            </Photo>
            <Photo>
              <Img src={person_photo_url} alt="User photo" />
            </Photo>
            <TextArea value={review ? review.comment : ""} readOnly></TextArea>
          </Flex>
        </>
      )}
      {/* </Flex> */}
    </Root>
  );
};

export default HistoryItem;
