import { sortingOrders } from "../constants";

export const sortByDate = (items, order) => {
  return [...items].sort((a, b) =>
    order === sortingOrders.desc
      ? new Date(b.created_at) - new Date(a.created_at)
      : new Date(a.created_at) - new Date(b.created_at)
  );
};

export const sortByName = (items, field, order) => {
  return [...items].sort((a, b) =>
    order === sortingOrders.desc
      ? b[field].localeCompare(a[field])
      : a[field].localeCompare(b[field])
  );
};
