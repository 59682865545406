import React, { useEffect } from "react";
import uuid from "uuid/v4";
import { connect } from "react-redux";

// Components
import HistoryItem from "../HistoryItem";

// Action creators
import { getProfileHistoryAction } from "../../redux/actionCreators";

const History = ({ profileHistory, currentUser, getProfileHistory }) => {
  useEffect(() => {
    if (currentUser) {
      getProfileHistory(currentUser.user_id);
    }
  }, [currentUser]);

  return (
    currentUser &&
    Boolean(profileHistory.length) &&
    profileHistory.map(profile => <HistoryItem key={uuid()} data={profile} />)
  );
};

const mapStateToProps = state => ({
  profileHistory: state.users.profileHistory,
  currentUser: state.users.currentUser
});

const mapDispatchToProps = dispatch => ({
  getProfileHistory: id => dispatch(getProfileHistoryAction(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(History);
